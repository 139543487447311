import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React, { Component } from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'reactstrap';

class Toast extends Component {
	render() {
		const CloseButton = ({ closeToast }) => (
			<Button onClick={closeToast} color="flat-secondary" className="rounded-circle btn-icon mr-n1">
				<Icon path={mdiClose} size="1.5rem"></Icon>
			</Button>
		);
		return (
			<ToastContainer
				position={toast.POSITION.BOTTOM_CENTER}
				transition={Slide}
				autoClose={5000}
				hideProgressBar
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				closeButton={CloseButton}
			/>
		);
	}
}

export default Toast;
